import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)
// // 解决ElementUI导航栏中的vue-router在3.0版本以上重复点菜单报错问题
// const originalPush = VueRouter.prototype.push
// VueRouter.prototype.push = function push(location) {
//   return originalPush.call(this, location).catch(err => err)
// }

const routes = [
  {
    path: '/',
    redirect: '/homeData'
  },
{
  path: '/home',
  name:'Home',
  //redirect: '/homeData',   //重定向
  component: () => import('@/components/layOut/index'),
  children:[
    {
      path: '/homeData',
      name:'HomeData',
      component: () => import('@/views/HomeData'),
    },
    {
      path: '/GoodsData',
      name:'GoodsData',
      component: () => import('@/views/GoodsData'),
    },
    {
      path: '/DetailsData',
      name:'DetailsData',
      component: () => import('@/views/DetailsData'),
    },
    {
      path: '/ShopCar',
      name:'ShopCar',
      component: () => import('@/views/ShopCar'),
    },
  ]
},
{
    path: '/Login',
    name:'Login',
    component: () => import('@/views/Login'),
},
{
  path: '/login/callback',
  name:'Callback',
  component: () => import('@/views/login/callback'),
},

{
  path: '/UserInfo',
  name:'UserInfo',
  //redirect: '/homeData',   //重定向
  component: () => import('@/components/layOut/UserInfo'),
  children:[
    {
      path: '/myOrder',
      name:'MyOrder',
      component: () => import('@/views/MyOrderList'),
    },
    {
      path: '/myAddress',
      name:'MyAddress',
      component: () => import('@/views/MyAddress'),
    },
  ]
},



]

const router = new VueRouter({
  routes
})

export default router
