import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)
const getters = {
  KEEPALIVES(state){     //监听keepAlives
		return state.keepAlives
  }
};
const state = {
  keepAlives:[],
};
const actions = {

}
const mutations = {
  SET_KEEP_ALIVE(state,obj){     //更改store中keepAlives的状态
 
    if(obj.type === 'add'){
      state.keepAlives.push(obj.name);
    }else {
      let index = state.keepAlives.findIndex(v => v === obj.name);
      state.keepAlives.splice(index,1);
    }
    state.keepAlives  = [...new Set(state.keepAlives)];
    console.log(state.keepAlives)
		return state.keepAlives;
	}
	}
export default new Vuex.Store({
  state,
  mutations,
  actions,
  getters
})
