import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '@/assets/css/common.css'
import '@/assets/css/element.css'
import '@/assets/iconfont/iconfont.css'
import '@/assets/iconfontColor/iconfont.css'
import { Container,Header,Aside,Main,Input,Icon,Button,Row,Col,Menu,Submenu,MenuItem,MenuItemGroup, Carousel,
  CarouselItem,Image, Tabs,TabPane, Link,Divider, Collapse,
  CollapseItem,Pagination,Loading, InputNumber,Tooltip,Upload,Footer,Dialog, Table,TableColumn,MessageBox,Badge,
  Popover,Avatar,Message,Form,FormItem,Tag,Checkbox,InfiniteScroll } from 'element-ui';
[Container,Header,Aside,Main,Input,Icon,Button,Row,Col,Menu,Submenu,MenuItem,MenuItemGroup, Carousel,
  CarouselItem,Image,Tabs,TabPane, Link,Divider, Collapse,
  CollapseItem,Pagination,Loading, InputNumber,Tooltip,Upload,
  Footer,Dialog,Table,TableColumn,Badge,Popover,Avatar,Form, FormItem,Tag,Checkbox,InfiniteScroll ].forEach(e =>{
  Vue.use(e);
})
import vRegion from 'v-region'
Vue.use(vRegion)
Vue.config.productionTip = false
Vue.prototype.$confirm = MessageBox.confirm;
Vue.prototype.$message = Message;
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
